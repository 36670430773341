





































import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import { RawLocation } from "vue-router";
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
interface Data<T> {
  menu: T;
  path: T;
}
interface Row {
  path: string;
  name: string;
}
interface RightClick {
  preventDefault: () => void;
  button: number;
  clientX: number;
  clientY: number;
}
interface RightMenu {
  (): void;
  (): void;
  (this: Document, ev: MouseEvent): any;
}
@Component({
  name: "navTabs",
})
export default class Home extends Vue {
  //注入父级的 刷新
  @Inject("refurbish")
  refurbish!: (arg0: Vue) => void;
  @Prop() Index!: Vue;
  @Prop({ type: Function }) getActiveIndex!: Function;
  @Watch("editableTabsValue")
  tabsValueChange() {
    //储存当前点击过的菜单数据，页面刷新重新赋值
    this.saveDataChange();
  }
  @Watch("editableTabs")
  tabsDataChange() {
    //菜单数据Tabs数组有变化，页面刷新重新赋值
    this.saveDataChange();
  }
  public editableTabs = [
    {
      name: "首页",
      path: "/home",
    },
  ];
  public rightMenu!: RightMenu;
  public rightItem!: Row;
  public IsActive = true;
  public editableTabsValue = "/home";

  public created() {
    //如果F5刷新，浏览器有记录默认加载之前储存的Tabs点击数据
    const record = sessionStorage.getItem("TabsData");
    if (record) {
      const data = JSON.parse(record);
      this.editableTabs = data.clickTabs;
      this.editableTabsValue = data.editableTabsValue;
      this.$router.push(this.editableTabsValue);
      this.$emit("getActiveIndex", this.editableTabsValue);
    }
    this.rightMenu = () => {
      document.addEventListener(
        "click",
        function() {
          const e = document.getElementById("menu") as HTMLElement;
          e && (e.style.display = "none");
        },
        false
      );
    };
    window.addEventListener("popstate", () => {
      const path = this.$route.path;
      //传递给menubar，让左侧菜单和导航栏选中内容对应
      this.$emit("getActiveIndex", path);
      this.editableTabsValue = path;
      this.$router.push(path);
      const menuData = JSON.parse(sessionStorage.getItem("menuData") as string);
      const menuItem = menuData.find((ele: { path: string }) => {
        return ele.path === path;
      });
      this.getMenuData(menuItem);
    });
  }
  public beforeDestroy() {
    document.removeEventListener("click", this.rightMenu);
  }
  //储存当前Tabs数据
  public saveDataChange() {
    const TabsData = {
      editableTabsValue: this.editableTabsValue,
      clickTabs: this.editableTabs,
    };
    sessionStorage.setItem("TabsData", JSON.stringify(TabsData));
  }
  //触发刷新操作
  public beforeLeave() {
    this.IsActive && this.refurbish(this.Index);
  }
  //在导航栏标签上右键触发 item为右键时对应的导航数据
  public rightClick(
    item: Row,
    event: {
      preventDefault: () => void;
      stopPropagation: () => void;
      button: number;
      clientX: any;
      clientY: any;
    }
  ) {
    const e = document.getElementById("menu") as HTMLElement;
    event.stopPropagation();
    event.preventDefault();
    this.rightItem = item;
    //等于2是浏览器默认的右键行为
    if (event.button === 2) {
      this.rightMenu();
      event.preventDefault();
      //定位右键菜单位置
      e.style.display = "block";
      e.style.left = `${event.clientX}px`;
      e.style.top = `${event.clientY}px`;
    }
  }
  //更新视图
  public updateData() {
    this.$forceUpdate();
    this.updataRoute();
  }
  //右键菜单刷新
  public refresh() {
    this.updataRoute();
    this.refurbish(this.Index);
  }
  //刷新视图,传递路由数据到menuBar
  public updataRoute() {
    const path = this.rightItem.path;
    //传递给menubar，让左侧菜单和导航栏选中内容对应
    this.$emit("getActiveIndex", path);
    this.editableTabsValue = path;
    this.$router.push(path);
  }
  //右键关闭右侧
  public closeRight() {
    const numIndex = this.editableTabs.findIndex((ele) => {
      return ele.path === this.rightItem.path;
    });
    this.editableTabs.length = numIndex + 1;
    this.updateData();
  }
  //右键关闭其他
  public closeOther() {
    const data = [
      {
        name: "首页",
        path: "/home",
      },
    ];
    //如果右键的是首页，就不需要在推入数据
    this.rightItem.path !== "/home" && data.push(this.rightItem);
    this.editableTabs = data;
    this.updateData();
  }
  //删除某个导航栏标签是，自动选中下一个
  public tabsRemove(targetName: string) {
    console.log("targetName :>> ", targetName);
    const tabs = this.editableTabs;
    let activeName = this.editableTabsValue;
    if (activeName === targetName) {
      tabs.forEach((tab, index) => {
        if (tab.path === targetName) {
          const nextTab = tabs[index + 1] || tabs[index - 1];
          if (nextTab) {
            console.log("nextTab :>> ", nextTab);
            activeName = nextTab.path;
          }
        }
      });
    }
    this.editableTabsValue = activeName;
    this.$router.push(activeName);
    //过滤出没被删除的菜单数据
    this.editableTabs = tabs.filter((tab) => tab.path !== targetName);
    //传递给menubar，让左侧菜单和导航栏选中内容对应
    this.$emit("getActiveIndex", activeName);
  }
  //点击某个导航栏标签，进行跳转
  public tabsClick(tab: { name: RawLocation }) {
    this.$router.push(tab.name);
    //传递给menubar，让左侧菜单和导航栏选中内容对应
    this.$emit("getActiveIndex", tab.name);
    //默认点击顶部导航栏菜单保持活跃
    this.IsActive = true;
  }
  //获取从组件menuBar上传的菜单数据
  public getMenuData(row: Row): void {
    const data: Data<string> | undefined | any = this.editableTabs.find(
      (ele) => {
        return ele.path === row.path;
      }
    );
    //如果导航栏已经有该菜单，赋值选中
    this.editableTabsValue = row.path;
    //如果导航栏没有该菜单，增加进导航栏
    !data && this.editableTabs.push(row);
    // console.log('菜单的 :>> ', this.editableTabs);
    //默认点击左侧菜单不保持活跃
    this.IsActive = false;
  }
}
