



















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { getMenu } from '@/serve/index';

// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

@Component({
  name: 'menuBar',
})
export default class Home extends Vue {
  @Prop({ type: Function }) clickMenu!: Function;
  public activeIndex = '/home';
  public isCollapse = false;
  public menuData = [];
  public created() {
    this.$router.push(this.activeIndex);
    this.getMenu();
  }
  //获取菜单
  public async getMenu() {
    const res = await getMenu();
    const menu: any[] = [];
    this.menuData = res.data;
    this.menuData.forEach((ele: any) => {
      menu.push(...ele.children);
    });
    sessionStorage.setItem('menuData', JSON.stringify(menu));
  }
  //筛选当前点击的菜单
  public handleSelect(key: unknown, keyPath: string[]) {
    const index = Number(keyPath[0]);
    this.activeIndex = keyPath[1];
    //触发父级方法
    this.$emit(
      'clickMenu',
      //找到对应的菜单
      (this.menuData[index] as { children: Array<any> }).children.find(
        (item) => {
          return item.path === keyPath[1];
        }
      )
    );
  }
}
