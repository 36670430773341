































import { Component, Mixins, Vue, Provide } from "vue-property-decorator";
import navigation from "@/components/index/navigation.vue"; // @ is an alias to /src
import menuBar from "@/components/index/menuBar.vue"; // @ is an alias to /src
import { GetPermissions } from "@/mixins/getPermissions.ts";
import navTabs from "@/components/index/navTabs.vue"; // @ is an alias to /src
interface Menu<T> {
  name: T;
  path: T;
  menu: string;
}

interface This {
  IsActive: boolean;
  isRouterAlive: boolean;
  $nextTick: (arg0: () => void) => void;
}
@Component({
  components: {
    navigation,
    menuBar,
    navTabs
  },
  name: "Index"
})
export default class Index extends Mixins(GetPermissions) {
  get key() {
    return this.$route.fullPath;
  }
  public isRouterAlive = true;
  public _this!: Vue;
  public created() {
    this._this = this;
    // console.log("permissions :>> ", this.permissions);
  }
  //提供刷新方法
  @Provide("refurbish")
  refurbish = (_this: This) => {
    console.log("刷新 :>> ");
    // _this.IsActive = false;
    _this.isRouterAlive = false;
    _this.$nextTick(() => {
      _this.isRouterAlive = true;
    });
  };

  //clickMenu 左侧菜单menuBar点击回调函数
  public clickMenu(menu: Menu<string>) {
    console.log("menu :>> ", menu);
    (this.$refs.navTabs as navTabs).getMenuData(menu);
  }
  //getCollapse 左侧菜单栏是否隐藏或显示
  public getCollapse(state: boolean) {
    (this.$refs.menuBar as menuBar).isCollapse = state;
  }
  //getActiveIndex path就是路由地址
  public getActiveIndex(path: string) {
    (this.$refs.menuBar as menuBar).activeIndex = path;
  }
}
