import request from '../../utils/request'

// 基本设置新增或修改/manage/request/token/submit
export function SubmitTokensubmit(data: any): Promise<any> {
  return request({
    method: 'POST',
    url: 'manage/request/token/submit',
    data: data,
  })
}

// 基本设置配置详情/manage/request/token/detail
export function getTokenDetail(): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/request/token/detail'
  })
}
